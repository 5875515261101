<template>
  <div class="ct-container">
    <div class="search-container">
      <el-form
        ref="queryForm"
        :model="queryForm"
        label-width="100px"
        class="lf-form"
        :inline="true"
      >
        <el-form-item>
          <el-button type="primary" @click="test">查询</el-button>
          <el-button type="primary" @click="test1">查询: queryLoading</el-button>
          <el-button type="primary" @click="test2">查询: 全局</el-button>
          <el-button type="primary" @click="test3">查询: 无loading</el-button>
          <el-button @click="resetForm('queryForm')">重置</el-button>
          <el-button type="primary" @click="handleEdit">新增</el-button>
          <el-button type="primary" @click="visiable = true">en-dialog</el-button>
          <div style="margin-top: 10px">
            <el-button type="primary" @click="changeLayout(0)">显示全部</el-button>
            <el-button type="primary" @click="changeLayout(1)">只显示导航</el-button>
            <el-button type="primary" @click="changeLayout(2)">全屏</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="ct-content">
      <en-table
        v-en-loading="enLoading"
        :loading="queryLoading"
        :data="datas"
        height="400"
        max-height="600"
      >
        <en-table-column
          prop="userId"
          label="用户编号"
        >
        </en-table-column>
        <en-table-column
          prop="userName"
          label="用户名称"
        >
        </en-table-column>
        <en-table-column
          prop="state"
          label="状态"
          :formatter="(v) => $formatSelect(v, stateOptions)"
        >
        </en-table-column>
        <template #hoverRow="{row}">
          <en-expand-buttons
            :data="[{icon: 'moren-liebiao', iconColor: 'red'}]"
            @button-click="handleEdit(row)"
          />
        </template>
      </en-table>
      <!--      <el-table-->
      <!--        border-->
      <!--        v-loading="queryLoading"-->
      <!--        class="lf-tables"-->
      <!--        :data="datas"-->
      <!--      >-->
      <!--        <el-table-column-->
      <!--          prop="userId"-->
      <!--          label="用户编号"-->
      <!--        >-->
      <!--        </el-table-column>-->
      <!--        <el-table-column-->
      <!--          prop="userName"-->
      <!--          label="用户名称"-->
      <!--        >-->
      <!--        </el-table-column>-->
      <!--        <el-table-column-->
      <!--          prop="state"-->
      <!--          label="状态"-->
      <!--          :formatter="formatter"-->
      <!--        >-->
      <!--        </el-table-column>-->
      <!--        <el-table-column-->
      <!--          fixed="right"-->
      <!--          label="操作"-->
      <!--          width="120"-->
      <!--        >-->
      <!--          <template slot-scope="scope">-->
      <!--            <el-button-->
      <!--              type="text"-->
      <!--              size="small"-->
      <!--              @click="handleEdit(scope.row)"-->
      <!--            >详情-->
      <!--            </el-button>-->
      <!--            <el-button-->
      <!--              type="text"-->
      <!--              size="small"-->
      <!--              @click="doDelete(scope.row, scope.$index)"-->
      <!--              v-if="scope.row.publishState !== 1"-->
      <!--            >删除-->
      <!--            </el-button>-->
      <!--          </template>-->
      <!--        </el-table-column>-->
      <!--      </el-table>-->
      <el-pagination
        class="lf-pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pagination.currentPage"
        :page-size.sync="pagination.pageSize"
        :page-sizes="pagination.sizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
    <div class="test">
      <div>
        <div>$padStart</div>
        <div>
          {{ fitlterStr | $padStart(4)}}
        </div>
        <div>$padEnd</div>
        <div>
          {{ fitlterStr | $padEnd(6)}}
        </div>
      </div>
    </div>
    <en-dialog
      :visible="visiable"
      title="这是title"
      en-fullscreen
      en-body-scroll
      @close="visiable=false"
    >
      <div class="a">
        正式一个半全屏的弹窗
      </div>
      <div>
        <el-select v-model="form.state" placeholder="请选择状态">
          <el-option
             v-for="item in stateOptions"
             :key="item.value"
             :label="item.label"
             :value="item.value">
         </el-option>
        </el-select>
      </div>
      <div slot="footer">
        <en-button @click="visiable=false">
          确定
        </en-button>
      </div>
    </en-dialog>
    <el-dialog
      title="用户维护"
      :close-on-click-modal="false"
      :before-close="(done) => handleCloseBeforeDialog(done, 'form')"
      :visible.sync="showEditDialog"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px"
        class="lf-form"
      >
        <el-form-item
          prop="userName"
          label="姓名"
        >
          <el-input
            v-model="form.userName"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="状态"
          prop="state">
          <el-select v-model="form.state" placeholder="请选择状态">
            <el-option
              v-for="item in stateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog('form')">取 消</el-button>
        <el-button type="primary" @click="doUpdate('form')" :loading="updateLoading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/**
 * @Author 李煜
 * @Date 2020/6/15
 * @Description 普通demo演示，包含请求的用法，和工程mixins的使用
 * 1. 接口请求service从api导出，或者模块下面导出
 * 2. 下面的Mixin只是一些实例，表示如果写自己的mixin和引入使用，工程内的mixin都不再全局自动注册。
 * 如果需要公共全局的mixin可以去en-js增加公共mixin，然后会自动注册
 * 3. 全局过滤器的使用，全局过滤器
 * 4. 公共方法的使用
 */
import {
  hideCardNo, getTimeStamp, queryMixin, formatSelect, request
} from "en-js"; // 引入公共方法
import { mapGetters } from "vuex";
import commonMixin from "@/mixins/example/common";
import updateMixin from "@/mixins/example/updateMixin";
import deleteMixin from "@/mixins/example/deleteMixin";
// import { queryMixin } from 'ly-js'
import { exampleService } from "@/api"; // 简单增删改查请求实例
// import { exampleService } from "@/api/example"; // 二选一, 第一种需要在api/index.js导出
import { exapmleOptions } from "@/assets/js/enum";

export default {
  name: "Demo1",
  components: {},
  mixins: [commonMixin, queryMixin, updateMixin, deleteMixin],
  data() {
    return {
      visiable: false,
      enLoading: null,
      fitlterStr: 1,
      thousandStr: 100.1,
      stateOptions: exapmleOptions,
      queryForm: {
        name: ""
      },
      ruleForm: {
        date: ""
      },
      form: {
        userName: "",
        state: ""
      },
      rules: {
        userName: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur"
          },
          {
            min: 5,
            max: 10,
            message: "长度在 5 到 10 个字符",
            trigger: "blur"
          }
        ],
        state: [
          {
            required: true,
            message: "请选择用户状态",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters([
      "userInfo"
    ])
  },
  methods: {
    changeLayout(layout) {
      this.$eventHub.$emit("CHANGE_LAYOUT", layout);
    },
    handleButtonClick(row) {
      this.handleEdit(row);
    },
    async timeout(ms = 2000) {
      await new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    @request(true, "enLoading", {
      subTitle: "123",
      title: "title"
    })
    async test() {
      const params = this.getQueryParams();
      await this.timeout(); // 只是为来延迟，表示下loading相关动画
      const res = await exampleService.list(params);
      this.datas = res.list || [];
    },
    @request(true, "queryLoading")
    async test1() {
      const params = this.getQueryParams();
      await this.timeout(); // 只是为来延迟，表示下loading相关动画
      const res = await exampleService.list(params);
      this.datas = res.list || [];
    },
    @request()
    async test2() {
      const params = this.getQueryParams();
      await this.timeout(); // 只是为来延迟，表示下loading相关动画
      const res = await exampleService.list(params);
      this.datas = res.list || [];
    },
    @request(false)
    async test3() {
      const params = this.getQueryParams();
      await this.timeout(); // 只是为来延迟，表示下loading相关动画
      const res = await exampleService.list(params);
      this.datas = res.list || [];
    },
    async query() {
      const params = this.getQueryParams();
      await this.timeout(); // 只是为来延迟，表示下loading相关动画
      const res = await exampleService.list(params);
      this.datas = res.list || [];
      return res;
    },
    async update() {
      await this.timeout(); // 只是为来延迟，表示下loading相关动画
      const params = {
        ...this.form
      };
      const res = await exampleService.update(params);
      this.doQuery();
      return res;
    },
    async delete(row) {
      await this.timeout(); // 只是为来延迟，表示下loading相关动画
      const { userId } = row;
      const res = await exampleService.delete(userId);
      this.doQuery();
      return res;
    }
  },
  activated() {
    this.doQuery();
    console.log(this);
  },
  mounted() {
    console.log(hideCardNo("18611112222", "手机号"));
    console.log(getTimeStamp(2020, 6, 15));
    console.log(formatSelect(1, this.stateOptions));
  }
};
</script>
<style lang="scss" scoped>
  .ct-container {
    position: relative;
    width: 100%;
    height: 100%;
    background: #ffffff;
  }
</style>
