/*
 * @Author: zhangjiaheng
 * @Date: 2020-07-10 16:16:48
 * @LastEditTime: 2022-01-12 17:31:27
 * @LastEditors: wuqi
 * @Description: 关于时间的一些方法
 * @FilePath: \user\src\tools\date.js
 */
import dayJs from "dayjs";

/**
 * @description 根据入参 判断是否在同一年内 如果不在返回年-月-日
 * 在 返回月-日
 * 如果是今天返回时分
 * dd时间
 */
const getDateFormat = (dd) => {
  if (!dd) {
    return "";
  }
  // 今天 HH:mm、今年：MM-dd、不是今年的：YYYY-MM-DD
  const theDate = dayJs(dd).format("YYYY-MM-DD");
  const todayDate = dayJs().format("YYYY-MM-DD");
  let reDate;
  if (theDate === todayDate) {
    // 今天
    reDate = dayJs(dd).format("HH:mm");
  } else {
    const theYear = new Date(theDate).getFullYear();
    const curYear = new Date(todayDate).getFullYear();
    if (theYear === curYear) {
      // 今年
      const arr = theDate.split("-");
      reDate = `${arr[1]}-${arr[2]}`;
    } else {
      // 不是今年
      reDate = theDate;
    }
  }
  return reDate;
};

// dayjs format 跟当前系统timeFormat 对应关系
const dayJsTimeFormat = {
  1: "YYYY-MM",
  2: "YYYY/MM",
  3: "YYYYMM",
  4: "YYYY/MM/DD",
  5: "YYYY/MM/DD HH:mm",
  6: "YYYYMMDD",
  7: "YYYYMMDD HH:mm",
  8: "YYYY-MM-DD",
  9: "YYYY-MM-DD HH:mm",
  10: "YYYY-MM-DD HH:mm:ss",
  11: "YYYY-MM-DD HH",
  12: "YYYY"
};
// dayjs format 跟当前系统timeFormat 对应关系
const endOfTimeFormat = {
  1: "month",
  2: "month",
  3: "month",
  4: "day",
  5: "minute",
  6: "day",
  7: "minute",
  8: "day",
  9: "minute",
  10: "second",
  11: "hour",
  12: "year"
};

/**
 * @description 根据入参 判断是否在同一年内 如果不在返回年-月-日
 * 在 返回月-日
 * 如果是今天返回今天+时分
 * dd时间
 */
const getDateFormatStr = (dd) => {
  if (!dd) {
    return "";
  }
  // 今天 HH:mm、今年：MM-dd、不是今年的：YYYY-MM-DD
  const theDate = dayJs(dd).format("YYYY-MM-DD");
  const todayDate = dayJs().format("YYYY-MM-DD");
  let reDate;
  if (theDate === todayDate) {
    // 今天
    reDate = `今天 ${dayJs(dd).format("HH:mm")}`;
  } else {
    const theYear = new Date(theDate).getFullYear();
    const curYear = new Date(todayDate).getFullYear();
    if (theYear === curYear) {
      // 今年
      const arr = theDate.split("-");
      reDate = `${arr[1]}-${arr[2]} ${dayJs(dd).format("HH:mm")}`;
    } else {
      // 不是今年
      reDate = `${theDate} ${dayJs(dd).format("HH:mm")}`;
    }
  }
  return reDate;
};

const getDateFormat2 = (dd) => {
  let ad = dayJs(dd).format("YYYY-MM-DD");
  let bd = dayJs().format("YYYY-MM-DD");
  let reDate;
  const theYear = new Date(dd).getFullYear();
  const curYear = new Date(bd).getFullYear();
  if (theYear === curYear) {
    // 当年
    ad = new Date(dd).getTime();
    bd = new Date().getTime();
    const diffHour = (bd - ad) / (3600 * 1000);
    if (diffHour < 1) {
      // 60分钟内
      reDate = `${Math.ceil(diffHour * 60)}分钟前`;
    } else if (diffHour >= 1 && diffHour < 24) {
      reDate = `${Math.ceil(diffHour)}小时前`;
    } else {
      const days = Math.ceil((bd - ad) / (24 * 3600 * 1000));
      if (days >= 1 && days < 7) {
        reDate = `${days}天前`;
      } else {
        reDate = `${dayJs(dd).format("MM-DD HH:mm")}`;
      }
    }
  } else {
    // 不是今年
    reDate = ad;
  }
  return reDate;
};

const date = {
  getDateFormat,
  getDateFormatStr,
  getDateFormat2
};
export { date, dayJsTimeFormat, endOfTimeFormat };
