import _ from "lodash";

export default {
  data() {
    return {
      queryDetailLoading: false,
      updateLoading: false,
      showEditDialog: false
    };
  },
  methods: {
    async handleEdit(form, isFn = false) {
      let first = false;
      if (!this.$refs.form) {
        first = true;
        this.defaultForm = _.cloneDeep(this.form);
      }
      if (this.defaultForm) {
        this.form = _.cloneDeep(this.defaultForm);
      }
      this.showEditDialog = true;
      if (first) {
        await this.$nextTick();
        this.resetForm("form");
      }
      if (form && form.type !== "click" && !isFn) {
        this.form = _.cloneDeep(form);
      }
      if (this.handleEditFormInit) {
        this.handleEditFormInit(form);
      }
      if (isFn && this.queryDetail) {
        try {
          this.queryDetailLoading = true;
          const res = await this.queryDetail(form);
          if (res.code !== 200) {
            this.netWarning(res);
          }
        } catch (e) {
          this.netError(e);
        } finally {
          this.queryDetailLoading = false;
        }
      }
    },
    async handleCloseBeforeDialog(done, formName = "form") {
      if (this.closeBeforeEditDialog) {
        this.closeBeforeEditDialog(done);
      } else {
        done();
      }
      if (formName) {
        this.resetForm(formName);
      }
    },
    handleCloseDialog(formName = "form") {
      if (formName && !formName.type) {
        this.resetForm(formName);
      }
      this.showEditDialog = false;
    },
    async doUpdate(formName) {
      if (!formName || formName.type === "click") {
        this._doUpdata(formName);
        return;
      }
      this.$refs[formName].validate(async(valid) => {
        if (!valid) {
          return false;
        }
        await this._doUpdata(formName);
        return true;
      });
    },
    async _doUpdata() {
      try {
        this.updateLoading = true;
        await this.update();
        this.updateLoading = false;
        // if (formName) {
        // this.resetForm(formName)
        // }
        this.showEditDialog = false;
      } catch (e) {
        console.error(e);
      } finally {
        this.updateLoading = false;
        if (this.updateEnd) {
          this.updateEnd();
        }
      }
    }
  }
};
