var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "example-select" },
    [
      _c("h1", [_vm._v("单选多选组件demo")]),
      _c(
        "el-form",
        [
          _c(
            "el-form-item",
            { attrs: { label: "searchKey匹配key搜索静态数据" } },
            [
              _c("en-select-data", {
                attrs: { config: _vm.searchConfig },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              }),
              _c("el-button", { on: { click: _vm.amendParams } }, [
                _vm._v("修改1111dependData传值"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "lazy人员树" } },
            [
              _c("en-select-data", {
                attrs: { config: _vm.personConfig },
                model: {
                  value: _vm.personValue,
                  callback: function ($$v) {
                    _vm.personValue = $$v
                  },
                  expression: "personValue",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "平行--纳税主体的字段" } },
            [
              _c("en-select-data", {
                attrs: { config: _vm.accountConfig },
                model: {
                  value: _vm.accountValue,
                  callback: function ($$v) {
                    _vm.accountValue = $$v
                  },
                  expression: "accountValue",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "自定义接口 自定义request报文" } },
            [
              _c("en-select-data", {
                attrs: { config: _vm.requestConfig },
                model: {
                  value: _vm.requestValue,
                  callback: function ($$v) {
                    _vm.requestValue = $$v
                  },
                  expression: "requestValue",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("h1", [_vm._v("穿梭框demo")]),
      _c("el-button", { on: { click: _vm.amendTransfer } }, [
        _vm._v("修改穿梭框dependData传值，改变穿梭框数据"),
      ]),
      _c("en-transfer-data", { attrs: { config: _vm.transferLeftConfig } }),
      _c("en-transfer-data", { attrs: { config: _vm.transferConfig } }),
      _c("en-transfer-data", { attrs: { config: _vm.transferPersonConfig } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }