import _ from "lodash";

export default {
  data() {
    return {
    };
  },
  methods: {
    async doDelete(row, index, title = "确认删除?", tipText) {
      try {
        let tip = tipText || this.deleteTip || "删除后，将不可恢复";
        if (_.isFunction(this.deleteTip)) {
          tip = this.deleteTip(row);
        }
        await this.$confirm(tip, title, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        });
        // this.$loading("删除中...");
        await this.delete(row, index);
        this.$message.success("删除成功");
      } catch (e) {
        console.error(e);
      } finally {
        // this.$hideLoading();
      }
    }
  }
};
