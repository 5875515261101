/* eslint-disable */
export const colList = [
  {
    aloneLineFlag: 1,
    appFieldName: "",
    appListShow: "1",
    appMainIsShow: 1,
    appSort: 5,
    appUpdateIsShow: "0",
    areaObjType: "002",
    areaObjTypeId: "",
    bgFileConfig: {},
    choiseField: "",
    color: "",
    dataSource: "1",
    field: "createId",
    fieldType: 5,
    isAdd: "1",
    isEdit: "1",
    isShow: "0",
    maxNum: "",
    name: "创建人",
    parentField: ""
  },
  {
    aloneLineFlag: 1,
    appFieldName: "",
    appListShow: "1",
    appMainIsShow: 1,
    appSort: 6,
    appUpdateIsShow: "0",
    areaObjType: "001",
    areaObjTypeId: "",
    bgFileConfig: {},
    choiseField: "",
    color: "",
    dataSource: "1",
    field: "createDepId",
    fieldType: 5,
    isAdd: "1",
    isEdit: "1",
    isShow: "0",
    maxNum: "",
    name: "创建人部门",
    parentField: ""
  },
  {
    aloneLineFlag: 1,
    appFieldName: "",
    appListShow: "1",
    appMainIsShow: 1,
    appSort: 7,
    appUpdateIsShow: "0",
    areaObjType: "001",
    areaObjTypeId: "",
    bgFileConfig: {},
    choiseField: "",
    color: "",
    dataSource: "1",
    field: "createOrgId",
    fieldType: 5,
    isAdd: "1",
    isEdit: "1",
    isShow: "0",
    maxNum: "",
    name: "创建人机构",
    parentField: ""
  },
  {
    aloneLineFlag: 1,
    appFieldName: "",
    appListShow: "1",
    appMainIsShow: 1,
    appSort: 8,
    appUpdateIsShow: "0",
    bgFileConfig: {},
    choiseField: "",
    color: "",
    field: "createTime",
    fieldType: 7,
    isAdd: "1",
    isEdit: "1",
    isShow: "0",
    maxNum: "",
    name: "创建时间",
    parentField: "",
    timeFormat: 9
  },
  {
    aloneLineFlag: 1,
    appFieldName: "",
    appListShow: "1",
    appMainIsShow: 1,
    appSort: 9,
    appUpdateIsShow: "0",
    bgFileConfig: {},
    choiseField: "",
    color: "",
    field: "billStatus",
    fieldType: 5,
    isAdd: "1",
    isEdit: "1",
    isShow: "0",
    maxNum: "",
    name: "状态",
    parentField: ""
  },
  {
    aloneLineFlag: 1,
    appFieldName: "",
    appListShow: "1",
    appMainIsShow: 1,
    appSort: 10,
    appUpdateIsShow: "0",
    bgFileConfig: {},
    choiseField: "",
    color: "",
    field: "modifyTime",
    fieldType: 7,
    isAdd: "1",
    isEdit: "1",
    isShow: "0",
    maxNum: "",
    name: "更新时间",
    parentField: "",
    timeFormat: 9
  },
  {
    aloneLineFlag: 1,
    appFieldName: "",
    appListShow: "1",
    appMainIsShow: 1,
    appSort: 9,
    appUpdateIsShow: "0",
    bgFileConfig: {},
    choiseField: "",
    color: "",
    field: "version",
    fieldType: 3,
    isAdd: "1",
    isEdit: "1",
    isShow: "0",
    maxNum: "",
    name: "模板版本",
    parentField: ""
  },
  {
    aloneLineFlag: 1,
    appFieldName: "",
    appListShow: "1",
    appMainIsShow: 1,
    appSort: 10,
    appUpdateIsShow: "0",
    areaObjTypeId: "MD_SORUCE_TYPE",
    bgFileConfig: {},
    choiseField: "",
    color: "",
    dataSource: 1,
    field: "dataSources",
    fieldType: 5,
    isAdd: "1",
    isEdit: "1",
    isShow: "0",
    maxNum: "",
    name: "数据来源",
    parentField: ""
  },
  {
    aloneLineFlag: 1,
    appFieldName: "",
    appListShow: "1",
    appMainIsShow: 1,
    appSort: 11,
    appUpdateIsShow: "0",
    bgFileConfig: {},
    choiseField: "",
    color: "",
    field: "mSummary",
    fieldType: 1,
    isAdd: "1",
    isEdit: "1",
    isShow: "0",
    maxNum: "",
    name: "业务摘要",
    parentField: ""
  },
  {
    aloneLineFlag: 1,
    appListShow: 0,
    appMainIsShow: 1,
    appSort: 0,
    appUpdateIsShow: 0,
    attrId: "323546814764687360",
    bgFileConfig: {},
    choiseField: {},
    color: "#606060",
    enclosureShowStyle: 1,
    enlargeStyle: 0,
    field: "code",
    fieldArea: 1,
    fieldType: 1,
    isAdd: "1",
    isEdit: "0",
    isPhotograph: 1,
    isShow: "0",
    isWatermark: 1,
    maxNum: "",
    name: "编号",
    pageHide: 1,
    parentField: "",
    parentId: "0",
    position: "{}",
    showContent: "[]",
    showPrefix: 0,
    showPrefixLength: "1",
    templateId: "322184616478838784",
    uuid: "bbf74ba9-bf71-4377-8eb6-35dfedc4f2d9"
  },
  {
    aloneLineFlag: 1,
    appListShow: 0,
    appMainIsShow: 1,
    appSort: 1,
    appUpdateIsShow: 0,
    attrId: "323546814764687361",
    bgFileConfig: {},
    choiseField: {},
    color: "#606060",
    enclosureShowStyle: 1,
    enlargeStyle: 0,
    field: "m6025",
    fieldArea: 1,
    fieldType: 1,
    isAdd: "1",
    isEdit: "0",
    isPhotograph: 1,
    isShow: "0",
    isWatermark: 1,
    maxNum: "",
    name: "车主姓名",
    pageHide: 1,
    parentField: "",
    parentId: "0",
    position: "{}",
    showContent: "[]",
    showPrefix: 0,
    showPrefixLength: "1",
    templateId: "322184616478838784",
    uuid: "f9a22c7c-4c42-4d59-8296-b43dba945235"
  },
  {
    aloneLineFlag: 1,
    appListShow: 0,
    appMainIsShow: 1,
    appSort: 2,
    appUpdateIsShow: 0,
    attrId: "323546814764687362",
    bgFileConfig: {},
    choiseField: {},
    color: "#606060",
    enclosureShowStyle: 1,
    enlargeStyle: 0,
    field: "m6027",
    fieldArea: 1,
    fieldType: 1,
    isAdd: "1",
    isEdit: "0",
    isPhotograph: 1,
    isShow: "0",
    isWatermark: 1,
    maxNum: "",
    name: "车辆机架号",
    pageHide: 1,
    parentField: "",
    parentId: "0",
    position: "{}",
    showContent: "[]",
    showPrefix: 0,
    showPrefixLength: "1",
    templateId: "322184616478838784",
    uuid: "8c5144a0-74a9-4ead-80d4-b50e6593b995"
  },
  {
    aloneLineFlag: 1,
    appListShow: 0,
    appMainIsShow: 1,
    appSort: 3,
    appUpdateIsShow: 0,
    attrId: "323546814764687363",
    baseDataArea: "宝马,兰博基尼,马自达,奔驰",
    bgFileConfig: {},
    choiseField: {},
    color: "#606060",
    dataArea: [
      {
        color: "#606060",
        exceptFlag: "1",
        fieldControl: [],
        id: "宝马",
        name: "宝马",
        requrein: "1"
      },
      {
        color: "#606060",
        exceptFlag: "1",
        fieldControl: [],
        id: "兰博基尼",
        name: "兰博基尼",
        requrein: "1"
      },
      {
        color: "#606060",
        exceptFlag: "1",
        fieldControl: [],
        id: "马自达",
        name: "马自达",
        requrein: "1"
      },
      {
        color: "#606060",
        exceptFlag: "1",
        fieldControl: [],
        id: "奔驰",
        name: "奔驰",
        requrein: "1"
      }
    ],
    dataSource: 2,
    enclosureShowStyle: 1,
    enlargeStyle: 0,
    field: "m6029",
    fieldArea: 1,
    fieldType: 5,
    isAdd: "1",
    isEdit: "0",
    isPhotograph: 1,
    isShow: "0",
    isWatermark: 1,
    maxNum: "",
    name: "车辆品牌",
    pageHide: 1,
    parentField: "",
    parentId: "0",
    position: "{}",
    showContent: "[]",
    showPrefix: 0,
    showPrefixLength: "1",
    templateId: "322184616478838784",
    uuid: "cfe8cb91-1799-4c1c-a5a1-265346598b9c"
  },
  {
    aloneLineFlag: 1,
    appListShow: 1,
    appMainIsShow: 1,
    appSort: 4,
    appUpdateIsShow: 0,
    attrId: "323546814764687364",
    bgFileConfig: {},
    choiseField: {},
    color: "#606060",
    dateRange: 1,
    enclosureShowStyle: 1,
    enlargeStyle: 0,
    field: "m6031",
    fieldArea: 1,
    fieldType: 7,
    isAdd: "1",
    isEdit: "0",
    isPhotograph: 1,
    isShow: "0",
    isWatermark: 1,
    maxNum: "",
    name: "维修时间",
    pageHide: 1,
    parentField: "",
    parentId: "0",
    position: "{}",
    showContent: "[]",
    showPrefix: 0,
    showPrefixLength: "1",
    templateId: "322184616478838784",
    timeFormat: 9,
    uuid: "cc3093d9-0828-4472-ac2e-42d795436876"
  },
  {
    aloneLineFlag: 1,
    appListShow: 1,
    appMainIsShow: 1,
    appSort: 5,
    appUpdateIsShow: 0,
    attrId: "323546814764687365",
    bgFileConfig: {},
    choiseField: {},
    color: "#606060",
    enclosureShowStyle: 1,
    enlargeStyle: 0,
    field: "m6033",
    fieldArea: 1,
    fieldType: 1,
    isAdd: "1",
    isEdit: "0",
    isPhotograph: 1,
    isShow: "0",
    isWatermark: 1,
    maxNum: "",
    name: "车辆故障",
    pageHide: 1,
    parentField: "",
    parentId: "0",
    position: "{}",
    showContent: "[]",
    showPrefix: 0,
    showPrefixLength: "1",
    templateId: "322184616478838784",
    uuid: "11924ea4-b2a6-4c22-8282-de2a3673de92"
  },
  {
    aloneLineFlag: 1,
    appListShow: 1,
    appMainIsShow: 1,
    appSort: 6,
    appUpdateIsShow: 0,
    attrId: "323546814764687366",
    bgFileConfig: {},
    choiseField: {},
    color: "",
    enclosureShowStyle: 1,
    enlargeStyle: 0,
    field: "mWorkflowLog",
    fieldArea: 1,
    fieldType: 44,
    isAdd: "1",
    isEdit: "1",
    isPhotograph: 1,
    isShow: "0",
    isWatermark: 1,
    maxNum: "",
    name: "查看流程",
    pageHide: 1,
    parentField: "",
    parentId: "0",
    position: "{}",
    showContent: "[]",
    showPrefix: 0,
    templateId: "322184616478838784",
    uuid: "6d4efe33-ff7f-4eaa-a469-b1b4acde87fd"
  }
];

export const rspData = {
  commParams: {
    fieldStatistics: [],
    sumFields: []
  },
  conditionUrl: "",
  hasFirst: false,
  hasLast: false,
  hasNext: false,
  hasPrevious: false,
  pageNo: 1,
  pageSize: 10,
  params: {},
  records: [
    {
      billStatus: "008",
      billStatusName: "已提交",
      btnList: [
        {
          btnArea: 2,
          btnKey: "delete",
          btnName: "删除",
          colour: "#4a485f",
          dataChanges: "[]",
          dataRanges: "[]",
          funcType: 1,
          icon: "10",
          id: "b15f1993d88711ea9892005056b6183b",
          isBatchData: 0,
          isOneData: 0,
          isSelect: 0,
          ruleIds: [],
          sortNum: 1,
          templateId: "322184616478838784",
          tenantId: "320085176439939072"
        },
        {
          btnArea: 2,
          btnKey: "edit",
          btnName: "编辑",
          colour: "#4a485f",
          dataChanges: "[]",
          dataRanges: "[]",
          funcType: 1,
          icon: "2",
          id: "b15f1861d88711ea9892005056b6183b",
          isBatchData: 0,
          isOneData: 0,
          isSelect: 0,
          ruleIds: [],
          sortNum: 2,
          templateId: "322184616478838784",
          tenantId: "320085176439939072"
        }
      ],
      code: "0002",
      createDepId: [
        {
          id: "320106860894101504",
          name: ""
        }
      ],
      createId: [
        {
          id: "320107216698937344",
          name: ""
        }
      ],
      createOrgId: "",
      hasChild: 1,
      id: "322913586800300032",
      isShare: "0",
      isUserLike: "001",
      m6025: "鑫鑫鑫",
      m6027: "A00012301233",
      m6029: [
        {
          id: "奔驰",
          name: "奔驰"
        }
      ],
      m6031: "2020-08-07 14:00:00",
      m6033: "车屁股没了",
      version: 1
    },
    {
      billStatus: "008",
      billStatusName: "已提交",
      btnList: [
        {
          btnArea: 2,
          btnKey: "delete",
          btnName: "删除",
          colour: "#4a485f",
          dataChanges: "[]",
          dataRanges: "[]",
          funcType: 1,
          icon: "10",
          id: "b15f1993d88711ea9892005056b6183b",
          isBatchData: 0,
          isOneData: 0,
          isSelect: 0,
          ruleIds: [],
          sortNum: 1,
          templateId: "322184616478838784",
          tenantId: "320085176439939072"
        },
        {
          btnArea: 2,
          btnKey: "edit",
          btnName: "编辑",
          colour: "#4a485f",
          dataChanges: "[]",
          dataRanges: "[]",
          funcType: 1,
          icon: "2",
          id: "b15f1861d88711ea9892005056b6183b",
          isBatchData: 0,
          isOneData: 0,
          isSelect: 0,
          ruleIds: [],
          sortNum: 2,
          templateId: "322184616478838784",
          tenantId: "320085176439939072"
        }
      ],
      code: "0001",
      createDepId: [
        {
          id: "320106860894101504",
          name: ""
        }
      ],
      createId: [
        {
          id: "320107216698937344",
          name: ""
        }
      ],
      createOrgId: "",
      hasChild: 1,
      id: "322913527012593664",
      isShare: "0",
      isUserLike: "001",
      m6025: "犇犇犇",
      m6027: "A0000123123",
      m6029: [
        {
          id: "兰博基尼",
          name: "兰博基尼"
        }
      ],
      m6031: "2020-08-07 10:30:00",
      m6033: "车前灯坏",
      version: 1
    }
  ],
  total: 2,
  totalPages: 1
};
