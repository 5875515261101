/**
 * @Author 李煜
 * @Date 2020/6/18
 * @Description 导出枚举数组文件
 * 前端自定义标准：[{value: 1, label: "1"}]
 */

/**
 * 测试例子，提供example/Demo1.vue 使用，业务模块请勿使用
 * @type {({label: string, value: number}|{label: string, value: number})[]}
 */
export const exapmleOptions  = [{
  value: 1,
  label: "正常"
}, {
  value: 0,
  label: "禁用"
}]
