<!--
 * @Descripttion: 单选多选 数据封装引用
 * @Author: 彭博
 * @Date: 2020-08-05 09:29:58
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-11-24 10:41:46
-->
<template>
  <div class="example-select">
    <h1>单选多选组件demo</h1>
    <el-form>
      <el-form-item label="searchKey匹配key搜索静态数据">
        <en-select-data v-model="value" :config="searchConfig"></en-select-data>
        <el-button @click="amendParams">修改1111dependData传值</el-button>
      </el-form-item>
      <el-form-item label="lazy人员树">
        <en-select-data v-model="personValue" :config="personConfig"></en-select-data>
      </el-form-item>
      <el-form-item label="平行--纳税主体的字段">
        <en-select-data v-model="accountValue" :config="accountConfig"></en-select-data>
      </el-form-item>
      <el-form-item label="自定义接口 自定义request报文">
        <en-select-data v-model="requestValue" :config="requestConfig"></en-select-data>
      </el-form-item>
    </el-form>
    <h1>穿梭框demo</h1>
      <el-button @click="amendTransfer">修改穿梭框dependData传值，改变穿梭框数据</el-button>
      <en-transfer-data :config="transferLeftConfig"></en-transfer-data>
      <en-transfer-data :config="transferConfig"></en-transfer-data>
      <en-transfer-data :config="transferPersonConfig"></en-transfer-data>
  </div>
</template>

<script>
import enSelectData from "@/components/en-select-data";
import enTransferData from "@/components/en-transfer-data";
import { voucherDetail, balanceService } from "@/api";

export default {
  name: "exampleSelect",
  components: {
    enSelectData,
    enTransferData
  },
  /* eslint-disable */
  data() {
    return {
      randomValue: 0,
      value: { id: "11,22", name: "自定义1,222", hasChildren: "001", any: "壹" },
      // value: [],
      personValue: "",
      accountValue: "",
      requestValue: "",
      // 搜索匹配key 配置数据
      searchConfig: {
        searchKeys: ["name", "any", "id"], // 搜索 壹 贰 叁 肆 或者自定义1  id = 11 22
        isMulti: false, // 是否多选
        showText: {fields:[{field:"name",fieldName:"名称"},{field:"any",fieldName:"编号"}],split:"--"}, // 显示辅助字段
        assistFloat: false,
        // selectable: { id: 22 },
        lazy: false, // 获取树形结构静态数据  请设置false  默认为true  dataSource == 11 默认设置false
        choiceRule: false, // 禁止勾选父项
        selectMode: "tree",
        // 设置默认label、value取值参数  参考en-tree中defaultProps
        // 当前属性可不传  如默认值无法匹配  可以自传
        defaultProps: {
          label: "name", // this.itemFormat,
          value: "id"
        },
        // dependData  type Object
        // 选择框数据依赖参数配置  参照老版本
        // dataSource == 11  配合 dataList  自定义静态数据
        dependData: {
          dataSource: 11,
          dataList: [
            { id: 11, name: "自定义1", hasChildren: "001", any: "壹" },
            { id: 22, name: "自定义2", hasChildren: "001", any: "贰" },
            { id: 33, name: "自定义3", hasChildren: "001", any: "叁" },
            { id: 44, name: "自定义4", hasChildren: "001", any: "肆" }
          ]
        }
      },
      // 人员数 配置数据
      personConfig: {
        isMulti: true, // 是否多选
        showText: {fields:[{field:"name",fieldName:"名称"},{field:"type",fieldName:"编号"}],split:"|"}, // 显示辅助字段
        // selectable: { type: "003" },
        page: true, // 开启分页
        lazy: true, // 获取静态数据  请设置false
        choiceRule: false,
        pageSize: 20,
        selectMode: "tree",
        dependData: {
          areaObjType: "002"
        }
      },
      //通用人员树
      commPersonConfig: {
        isMulti: false,
        page: true,
        lazy: true,
        choiceRule: true,
        pageSize: 20,
        selectMode: "tree",
        dependData: {
          commType: 1
        }
      },
      // 会计科目 配置数据
      accountConfig: {
        isMulti: true, // 是否多选
        placeholder: "纳税主体的字段",
        selectMode: "normal",
        dependData: {
          areaObjType: "100",
          areaObjTypeId: 1
        }
      },
      // 自定义请求接口service  自定义请求报文
      requestConfig: {
        isMulti: true, // 是否多选
        placeholder: "自定义请求服务",
        pageSize: 10,
        selectMode: "tree",
        dependData: {
          // 当前服务引入与 src\views\intelligentAccounting\components\checkCashFlow\index.vue 104行中
          requestService: balanceService, // 引入服务名称  
          requestFn: "queryDeptUserTree", // 服务中方法
          requestParams: { id: 0 } // 请求参数
          // requestParams: { objType: "017", pageNo: 1, pageSize: 9999 } // 请求参数
        },
        // 设置默认label、value取值参数  参考en-tree中defaultProps
        defaultProps: {
          label: "name",
          value: "id",
          isLeaf: "leaf"
        }
      },
      transferLeftConfig: {
        isMulti: true, // 是否多选
        hideRight: true,
        dependData: {
          // 当前服务引入与 src\views\intelligentAccounting\components\checkCashFlow\index.vue 104行中
          requestService: voucherDetail, // 引入服务名称  
          requestFn: "queryObjectTree", // 服务中方法
          requestParams: { objType: "017", pageNo: 1, pageSize: 9999 } // 请求参数
        },
        // 设置默认label、value取值参数  参考en-tree中defaultProps
        defaultProps: {
          label: this.itemFormat,
          value: "id"
        }
      },
      transferConfig: {
        isMulti: true, // 是否多选
        hideRight: false,
        // showHead: true, // 右侧选中的数据是否需要显示头像
        // imgPath: "", // 数据的头像承载字段 图片地址所在的字段
        dependData: {
          // 当前服务引入与 src\views\intelligentAccounting\components\checkCashFlow\index.vue 104行中
          requestService: voucherDetail, // 引入服务名称  
          requestFn: "queryObjectTree", // 服务中方法
          requestParams: { objType: "017", pageNo: 1, pageSize: 9999 } // 请求参数
        },
        // 设置默认label、value取值参数  参考en-tree中defaultProps
        defaultProps: {
          label: "name",
          value: "id"
        }
      },
      transferPersonConfig: {
        isMulti: true,
        hideRight: false,
        dependData: {
          commType: 1
        }
      }
    };
  },
  watch: {
    value(val) {
      console.log("watch ---- 选中内容", val);
    }
  },
  methods: {
    itemFormat(data) {
      return `${data.code  || ""} ${data.name || ""}`
    },
    amendParams() {
      let n = this.randomValue;
      while(n === this.randomValue) {
        n = Math.floor(Math.random() * 3 + 1);
      }
      this.randomValue = n;
      this.searchConfig.dependData = {
        dataSource: 11,
        dataList: [
          { id: 11 + n , name: "自定义1" + n , hasChildren: "001", any: "壹" + n },
          { id: 22 + n , name: "自定义2" + n , hasChildren: "001", any: "贰" + n  },
          { id: 33 + n , name: "自定义3" + n , hasChildren: "001", any: "叁" + n  },
          { id: 44 + n , name: "自定义4" + n , hasChildren: "001", any: "肆" + n  }
        ]
      }
    },
    amendTransfer() {
      let n = this.randomValue;
      while(n === this.randomValue) {
        n = Math.floor(Math.random() * 3 + 1);
      }
      this.randomValue = n;
      let serviceObj = {};
      switch (n) {
        case 1:
          serviceObj = {
            dataSource: 11,
            dataList: [
              { id: 11, name: "自定义1", hasChildren: "001", any: "壹" },
              { id: 22, name: "自定义2", hasChildren: "001", any: "贰" },
              { id: 33, name: "自定义3", hasChildren: "001", any: "叁" },
              { id: 44, name: "自定义4", hasChildren: "001", any: "肆" }
            ]
          };
          break;
        case 2:
          serviceObj.requestService = voucherDetail;
          serviceObj.requestFn = "queryObjectTree";
          serviceObj.requestParams = { objType: "017", pageNo: 1, pageSize: 9999 };
          break;
        case 3:
          serviceObj.requestService = balanceService;
          serviceObj.requestFn = "queryDeptUserTree";
          serviceObj.requestParams = {
            id: 0,
            pageNo: 1,
            pageSize: 20
          };
          break;
      }
      console.log("what ??", serviceObj);
      this.transferLeftConfig.dependData = serviceObj;
      // Object.assign(this.transferConfig.dependData, serviceObj);
    }
  }
};
</script>

<style lang="scss" scoped>
.example-select {
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow-y: auto;
  padding: 50px 30px;
  box-sizing: border-box;
}
.transfer-data{
  margin-bottom: 20px;
}
</style>
