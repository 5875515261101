var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "test-entry" }, [
    _c(
      "div",
      { staticClass: "test-list" },
      [
        _c("div", { staticClass: "header", staticStyle: { color: "green" } }, [
          _vm._v("测试清单(更新日期:2020-07-30)"),
        ]),
        _c(
          "en-table",
          { attrs: { data: _vm.testList } },
          [
            _c("en-table-column", { attrs: { type: "index", width: "180px" } }),
            _c("en-table-column", {
              attrs: { prop: "name", label: "功能模块" },
            }),
            _c("en-table-column", { attrs: { prop: "desc", label: "描述" } }),
            _c("en-table-column", {
              attrs: { prop: "spec", label: "特殊(暂不能测试)" },
            }),
            _c("en-table-column", {
              attrs: { prop: "time", label: "提测时间" },
            }),
            _c("en-table-column", {
              attrs: { prop: "person", label: "负责人" },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "dev-list" },
      [
        _c("div", { staticClass: "header", staticStyle: { color: "red" } }, [
          _vm._v("即将提测清单(更新日期:2020-08-01)"),
        ]),
        _c(
          "en-table",
          { attrs: { data: _vm.devList } },
          [
            _c("en-table-column", { attrs: { type: "index", width: "180px" } }),
            _c("en-table-column", {
              attrs: { prop: "name", label: "功能模块" },
            }),
            _c("en-table-column", { attrs: { prop: "desc", label: "描述" } }),
            _c("en-table-column", {
              attrs: { prop: "person", label: "负责人" },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "progress-list" },
      [
        _c("div", { staticClass: "header", staticStyle: { color: "blue" } }, [
          _vm._v("主要模块的进度(更新日期:2020-08-01)"),
        ]),
        _c(
          "en-table",
          { attrs: { data: _vm.progress } },
          [
            _c("en-table-column", { attrs: { type: "index", width: "180px" } }),
            _c("en-table-column", { attrs: { prop: "name", label: "模块" } }),
            _c("en-table-column", {
              attrs: { prop: "progress", label: "进度" },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }