<!--
 * @Descripttion: 通用列表
 * @Author: 彭博
 * @Date: 2020-08-17 14:43:02
 * @LastEditors: 彭博
 * @LastEditTime: 2020-08-20 15:04:37
-->
<template>
  <div class="example-table">
    <h1>通用列表</h1>
    <en-table
      id="printMe"
      ref="table"
      :data="rspData.records"
      row-id="id"
      @filter-sort-change="handleFilterSortChange"
      :span-method="arraySpanMethod"
      :expand-config="{ expandRowIds: expandKeys }"
    >
      <en-table-column type="index-selection"> </en-table-column>
      <en-table-column type="expand" v-show="false" label="展开"></en-table-column>
      <en-table-column prop="nothing" label="子集">
        <template slot-scope="scope">
          <div @click="handleExpand(scope)">子集内容</div>
        </template>
      </en-table-column>
      <table-column v-for="item in colList" :key="item.field" :data="item" :filter-sort-config="{ sortable: true, filterable: true }"> </table-column>
      <!-- <template v-slot="{ scope }">{{item.field}}--{{item.fieldType}}---{{ scope.row[item.field]}}</template> -->
      <template  #hoverRow="{row, rowIndex}">
        <en-expand-buttons :data="buttonData" :minNumber="buttonClick.length" :lineIndex="rowIndex" @button-click="buttonClick" />
      </template>
      <template #headEnd>
        <div class="pd-rt-btn">
          <en-expand-buttons :data="headBtnData" :minNumber="headBtnData.length" @button-click="headButtonClick" >
          </en-expand-buttons>
        </div>
      </template>
    </en-table>
    <button @click="print">print ----- table</button>
  </div>
</template>

<script>
/* eslint-disable */

import dayjs from "dayjs";
import printJS from "print-js";
import enTransferData from "@/components/en-transfer-data";
import tableColumn from "@/components/en-table-extra/tableColumn";
import { colList, rspData } from "@/mock/data/list.js";

export default {
  name: "exampleTable",
  components: {
    tableColumn,
    enTransferData,
    printJS
  },
  computed: {
    hasExpand() {
      return true;
    }
  },
  data() {
    return {
      expandKeys: [], // 默认展开行id
      colList: colList,
      rspData: rspData,
      headBtnData: [
        {
          name: "新增",
          icon: "tianjia-liebiao",
          iconColor: "#4895DF",
          fn: this.addOneLine
        }
      ],
      buttonData: [
        {
          name: "新增",
          icon: "tianjia-liebiao",
          iconColor: "#4895DF",
          fn: this.addOneLine
        },
        {
          name: "复制",
          icon: "fuzhi-kuaijiecaozuo",
          iconColor: "#58CB7E",
          fn: this.copyOneLine
        },
        {
          name: "删除",
          icon: "shibai",
          iconColor: "#F76B6B",
          fn: this.deleteOneLine
        }
      ],
      transferConfig: {
        hideRight: true,
        modeType: "tree",
        // isMulti: false,
        dependData: {
          dataSource: 11,
          dataList: [
            { name: "男", six: 1 },
            { name: "女", six: 0 }
          ]
        }
      }
    };
  },
  methods: {
    print() {
      printJS({
        printable: "printMe",
        type: "html",
        header: "打印",
        documentTitle: "河洛为企业盈利"
      })
    },
    // 点击 展开当前行
    handleExpand({ row }) {
      this.$refs.table.setRowExpansion(row);
    },
    arraySpanMethod({ rowIndex, columnIndex}) {
      if (this.hasExpand && columnIndex === 2) {
        console.log("has expand", rowIndex, columnIndex);
        return {
            rowspan: 1,
            colspan: 2
          };
      }
      if (this.hasExpand && columnIndex === 1) {
        console.log("has expand", rowIndex, columnIndex);
        return {
            rowspan: 0,
            colspan: 0
          };
      }
    },
    // 行button组 点击处理
    buttonClick(index, icon, lineIndex) {
      const btnData = this.buttonData[index];
      const { fn } = btnData;
      typeof fn === "function" && fn(lineIndex);
    },
    headButtonClick(index) {
      const btnData = this.headBtnData[index];
      const { fn } = btnData;
      typeof fn === "function" && fn();
    },
    addOneLine() {
      this.$message("新增一行");
    },
    deleteOneLine(index) {
      this.$message(`删除第${index}行`);
    },
    // 复制一行
    copyOneLine(index) {
      this.$message(`复制第${index}行`);
    },
    handleColumnFilterChange(e) {
      console.log("filter change", e);
    },
    handleFilterSortChange(e) {
      console.log("sort change", e);
    }
  },
  created() {
    this.expandKeys.push("322913586800300032");
  }
};
</script>

<style scope lang="scss">
.example-table {
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow-y: auto;
  padding: 50px 30px;
  box-sizing: border-box;
}

.pd-rt-btn{
  padding-right: 10px;
  cursor: pointer;
}
</style>
