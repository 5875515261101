<!--
 * @Description: 测试页面
 * @Author: 梁平贤
 * @Date: 2020-06-20 11:53:17
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-07-21 15:09:03
-->
<template>
  <div class="test-entry">
    <div class="test-list">
      <div class="header" style="color:green;">测试清单(更新日期:2020-07-30)</div>
      <en-table :data="testList">
        <en-table-column type="index" width="180px"></en-table-column>
        <en-table-column
          prop="name"
          label="功能模块"
        >
        </en-table-column>
        <en-table-column
          prop="desc"
          label="描述"
        >
        </en-table-column>
        <en-table-column
          prop="spec"
          label="特殊(暂不能测试)"
        >
        </en-table-column>
        <en-table-column
          prop="time"
          label="提测时间"
        >
        </en-table-column>
        <en-table-column
          prop="person"
          label="负责人"
        >
        </en-table-column>
      </en-table>
    </div>
    <div class="dev-list">
      <div class="header" style="color:red">即将提测清单(更新日期:2020-08-01)</div>
      <en-table :data="devList">
        <en-table-column type="index" width="180px"></en-table-column>
        <en-table-column
          prop="name"
          label="功能模块"
        >
        </en-table-column>
        <en-table-column
          prop="desc"
          label="描述"
        >
        </en-table-column>
        <en-table-column
          prop="person"
          label="负责人"
        >
        </en-table-column>
      </en-table>
    </div>
    <div class="progress-list">
      <div class="header" style="color:blue">主要模块的进度(更新日期:2020-08-01)</div>
      <en-table :data="progress">
        <en-table-column type="index" width="180px"></en-table-column>
        <en-table-column
          prop="name"
          label="模块"
        >
        </en-table-column>
        <en-table-column
          prop="progress"
          label="进度"
        >
        </en-table-column>
      </en-table>
    </div>
  </div>
</template>
<script>

export default {
  name: "TestEntry",
  data() {
    return {
      // 可供测试清单
      testList: [
        {
          name: "登录",
          desc: "登录,退出,登录重定向",
          spec: "扫码登录,修改密码",
          time: "2020-07-30",
          person: "李煜"
        },
        {
          name: "待办/已办/我的业务/我的订单/消息中心/我的河洛",
          desc: "登录,登录重定向",
          spec: "穿透到其它功能",
          time: "2020-07-30",
          person: "张家衡"
        },
        {
          name: "个人信息",
          desc: "基本信息,偏好管理,授权管理,安全设置",
          spec: "",
          time: "2020-07-30",
          person: "朱琳"
        },
        {
          name: "菜单,自定义菜单,首页自定义",
          desc: "",
          spec: "",
          time: "2020-07-30",
          person: "袁银华"
        }
      ],
      // 即将提测清单
      devList: [
        {
          name: "酒店",
          desc: "酒店查询,提交等",
          spec: "",
          time: "",
          person: "胡聪林"
        },
        {
          name: "业务建模看板-列表(切换)",
          desc: "",
          spec: "",
          time: "",
          person: "列表:徐俊 看板:张家衡"
        },
        {
          name: "我的文件夹",
          desc: "",
          spec: "",
          time: "",
          person: "周晴龙"
        },
        {
          name: "办公管理-考勤",
          desc: "",
          spec: "",
          time: "",
          person: "胡聪林"
        },
        {
          name: "企业征信",
          desc: "",
          spec: "",
          time: "",
          person: "朱琳"
        },
        {
          name: "帮助中心,下载中心,工资条(hr)",
          desc: "",
          spec: "",
          time: "",
          person: "帮助中心:张小兵  下载中心:刘建久  工资条HR: 葛立平"
        }
      ],
      //  主要模块的进度
      progress: [
        {
          name: "业务建模",
          progress: "70%"
        },
        {
          name: "分析建模",
          progress: "20%"
        },
        {
          name: "凭证建模",
          progress: "55%"
        },
        {
          name: "税务",
          progress: "0%"
        },
        {
          name: "商旅",
          progress: "50%"
        }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
  .test-entry {
    background-color: #fff;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    .header {
      padding: 20px;
    }
  }
</style>
