export default {
  data() {
    return {
    };
  },
  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    /**
     * 通用table列格式化
     * @param row
     * @param column
     * @param cellValue
     */
    formatter(row, column, cellValue) {
      if (!cellValue && cellValue !== 0) {
        return cellValue;
      }
      const type = column.property;
      const r = this.getFormatterValue(type, cellValue, row);
      return r;
    },
    formatterPercent(row, column, cellValue) {
      if (!cellValue) {
        return "0%";
      }
      return `${Number(cellValue).toFixed(2)}%`;
    },
    formatterFixedTow(row, column, cellValue) {
      if (!cellValue) {
        return "0.00";
      }
      return Number(cellValue).toFixed(2);
    },
    getFormatterValue(type, value, row) {
      const key = `${type}Options`;
      const options = this[key];
      if (!options) {
        let r = value;
        const customFn = this[`${type}Formatter`];
        if (customFn) {
          r = customFn(value, row);
        }
        return r;
      }
      const index = options.findIndex((v) => value.toString() === v.value.toString());
      if (index === -1) {
        return value;
      }
      return options[index].label;
    }
  }
};
